import { AppComponent } from './app.component';
import { ErrorComponent } from './core/error/error.component';
import { AccessDeniedComponent } from './core/access-denied/access-denied.component';
import { BaseComponent } from './components/base/base.component';
import { HeaderComponent } from './components/common-layout/header/header.component';
import { FooterComponent } from './components/common-layout/footer/footer.component';
import { RibsComponent } from './components/common-layout/ribs/ribs.component';
import { UserMenuComponent } from './components/common-layout/header/user-menu/user-menu.component';
import { MainMenuComponent } from './components/common-layout/header/main-menu/main-menu.component';
import { HelpMenuComponent } from './components/common-layout/header/help-menu/help-menu.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardItemComponent } from './components/items/dashboard-item/dashboard-item.component';
import { FlowDefinitionComponent } from './pages/flow-definition/flow-definition.component';
import { FlowDefinitionDetailsComponent } from './components/details/flow-definition-details/flow-definition-details.component';
import { FlowDefinitionDetailsHotConfigsComponent } from './components/details/flow-definition-details/flow-definition-details-hot-configs/flow-definition-details-hot-configs.component';
import { FlowObjectDetailsComponent } from './components/details/flow-object-details/flow-object-details.component';
import { FlowObjectDetailsDocumentComponent } from './components/details/flow-object-details/flow-object-details-document/flow-object-details-document.component';
import { FlowObjectDetailsForwardComponent } from './components/details/flow-object-details/flow-object-details-forward/flow-object-details-forward.component';
import { FlowObjectDetailsRegisterProcessComponent } from './components/details/flow-object-details/flow-object-details-register-process/flow-object-details-register-process.component';
import { FlowObjectDetailsDispatchProcessComponent } from './components/details/flow-object-details/flow-object-details-dispatch-process/flow-object-details-dispatch-process.component';
import { AccessLevelComponent } from './components/details/flow-object-details/edocs-fields/access-level/access-level.component';
import { DocumentClassComponent } from './components/details/flow-object-details/edocs-fields/document-class/document-class.component';
import { EDocsLocationComponent } from './components/details/flow-object-details/edocs-fields/edocs-location/edocs-location.component';
import { FlowObjectDetailsFormComponent } from './components/details/flow-object-details/flow-object-details-form/flow-object-details-form.component';
import { FlowObjectDetailsStartInboundComponent } from './components/details/flow-object-details/flow-object-details-start-inbound-api/flow-object-details-start-inbound-api.component';
import { FlowObjectDetailsOutboundApiComponent } from './components/details/flow-object-details/flow-object-details-outbound-api/flow-object-details-outbound-api.component';
import { FlowObjectDetailsMidwayOutboundApiComponent } from "./components/details/flow-object-details/flow-object-details-midway-outbound-api/flow-object-details-midway-outbound-api.component";
import { FlowObjectDetailsGatewayRulesComponent } from './components/details/flow-object-details/flow-object-details-gateway-rules/flow-object-details-gateway-rules.component';
import { FlowObjectDetailsGatewayPathsComponent } from './components/details/flow-object-details/flow-object-details-gateway-paths/flow-object-details-gateway-paths.component';
import { FlowDefinitionItemComponent } from './components/items/flow-definition-item/flow-definition-item.component';
import { AvailableFlowObjectComponent } from './components/available-flow-object/available-flow-object.component';
import { SelectedGroupingComponent } from './components/selected-grouping/selected-grouping.component';
import { FlowDefinitionListComponent } from './pages/flow-definition-list/flow-definition-list.component';
import { FlowObjectDetailsModelEditorComponent } from './components/details/flow-object-details/flow-object-details-model-editor/flow-object-details-model-editor.component';
import { PdfUploadComponent } from './components/formio/pdf-upload/pdf-upload.component';
import { PdfUploadMultipleComponent } from './components/formio/pdf-upload-multiple/pdf-upload-multiple.component';
import { FileUploadComponent } from './components/formio/file-upload/file-upload.component';
import { DateTimePickerComponent } from './components/formio/datetime-picker/datetime-picker.component';
import { DatePickerComponent } from './components/formio/date-picker/date-picker.component';
import { FeatureHintComponent } from './components/feature-hint/feature-hint.component';
import {
    NameFilter,
    IsActiveFilter,
    IsPublishedFilter
} from './pages/flow-definition-list/flow-definition-list.pipe';
import {
    ClassesFilter,
    ComissoesFilter,
    FundamentosFilter,
    GruposFilter,
    PatriarcasFilter,
    OrganizacoesFilter,
    PlanosFilter,
    UnidadesFilter,
    PapeisFilter
} from './components/details/flow-object-details/flow-object-details.pipe';
import { CatalogServicesFilter } from './components/details/flow-definition-details/flow-definition-details.pipe';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularResizedEventModule } from 'angular-resize-event';
import { FormioModule } from '@formio/angular';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMatPopoverModule } from './components/ngx-mat-popover/ngx-mat-popover.module';
import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { CustomDateAdapter } from './shared/custom-date-adapter';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS, NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AppRoutingModule } from './app-routing.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AuthInterceptor } from './core/http/auth.interceptor';

import { ConfirmationService } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { registerPdfUploadComponent } from './components/formio/pdf-upload/pdf-upload.formio';
import { registerPdfUploadMultipleComponent } from './components/formio/pdf-upload-multiple/pdf-upload-multiple.formio';
import { registerDateTimePickerComponent } from './components/formio/datetime-picker/datetime-picker.formio';
import { registerDatePickerComponent } from './components/formio/date-picker/date-picker.formio';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent,
        AccessDeniedComponent,
        BaseComponent,
        HeaderComponent,
        FooterComponent,
        LandingPageComponent,
        RibsComponent,
        UserMenuComponent,
        MainMenuComponent,
        HelpMenuComponent,
        DashboardComponent,
        DashboardItemComponent,
        FlowDefinitionComponent,
        FlowDefinitionDetailsComponent,
        FlowDefinitionDetailsHotConfigsComponent,
        FlowObjectDetailsComponent,
        FlowObjectDetailsDocumentComponent,
        FlowObjectDetailsForwardComponent,
        FlowObjectDetailsRegisterProcessComponent,
        FlowObjectDetailsDispatchProcessComponent,
        AccessLevelComponent,
        DocumentClassComponent,
        EDocsLocationComponent,
        FlowObjectDetailsFormComponent,
        FlowObjectDetailsStartInboundComponent,
        FlowObjectDetailsGatewayRulesComponent,
        FlowObjectDetailsGatewayPathsComponent,
        FlowObjectDetailsMidwayOutboundApiComponent,
        FlowObjectDetailsOutboundApiComponent,
        FlowDefinitionItemComponent,
        AvailableFlowObjectComponent,
        SelectedGroupingComponent,
        FlowDefinitionListComponent,
        FlowObjectDetailsModelEditorComponent,
        PdfUploadComponent,
        PdfUploadMultipleComponent,
        FileUploadComponent,
        DateTimePickerComponent,
        DatePickerComponent,
        FeatureHintComponent,
        NameFilter,
        IsActiveFilter,
        IsPublishedFilter,
        PlanosFilter,
        ClassesFilter,
        FundamentosFilter,
        PatriarcasFilter,
        OrganizacoesFilter,
        UnidadesFilter,
        GruposFilter,
        ComissoesFilter,
        PapeisFilter,
        CatalogServicesFilter
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        DragDropModule,
        AngularResizedEventModule,
        FormioModule,
        NgxSpinnerModule,
        NgxMatPopoverModule,
        NgxMatMomentModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxChartsModule,
        MatTooltipModule,
        MatChipsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatDialogModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatDatepickerModule,
        MatIconModule,
        ToolbarModule,
        ButtonModule,
        ConfirmDialogModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            maxOpened: 5,
            positionClass: 'toast-top-right',
            preventDuplicates: false,
            enableHtml: true,
            progressBar: true,
            progressAnimation: 'increasing'
        }),
        AppRoutingModule,
        RouterModule,
        EditorModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        ConfirmationService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: NgxMatDateAdapter, useClass: CustomDateAdapter },
        { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: NGX_MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: "DD/MM/YYYY, [às] HH:mm:ss"
                },
                display: {
                    dateInput: "DD/MM/YYYY, [às] HH:mm:ss",
                    monthYearLabel: "MMM YYYY",
                    dateA11yLabel: "LLL",
                    monthYearA11yLabel: "MMMM YYYY"
                }
            }
        },
        AuthService,
        CookieService,
        NameFilter,
        IsActiveFilter,
        IsPublishedFilter,
        PlanosFilter,
        ClassesFilter,
        FundamentosFilter,
        PatriarcasFilter,
        OrganizacoesFilter,
        UnidadesFilter,
        GruposFilter,
        ComissoesFilter,
        PapeisFilter,
        CatalogServicesFilter
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(injector: Injector) {
        registerPdfUploadComponent(injector);
        registerPdfUploadMultipleComponent(injector);
        registerDateTimePickerComponent(injector);
        registerDatePickerComponent(injector);
    }
}
