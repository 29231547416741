import {
    Component,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    OnInit,
    AfterContentChecked,
    AfterViewInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import { FlowDefinition, FlowDefinitionContext } from '../../models/flow.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FlowDefinitionService } from '../../services/flow-definition.service';
import { BaseComponent } from '../../components/base/base.component';
import { ConfirmationService } from 'primeng/api';
import { Enums } from '../../shared/enums';
import { AuthService } from '../../services/auth.service';
import { NameFilter, IsActiveFilter, IsPublishedFilter } from './flow-definition-list.pipe';
import { SCSS_VARS } from '../../shared/shared-scss-ts-variables';
import { FlowDefinitionItemComponent } from '../../components/items/flow-definition-item/flow-definition-item.component';
import { CookieService } from 'ngx-cookie-service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { FlowDefinitionContextService } from '../../services/flow-definition-context.service';
import { Utils } from '../../shared/utils';
import { FlowRules } from '../../shared/flow-rules';
import { FlowObjectDefinitionService } from '../../services/flow-object-definition.service';

@Component({
    selector: 'flow-definition-list',
    templateUrl: './flow-definition-list.component.html',
    styleUrls: ['./flow-definition-list.component.scss']
})
export class FlowDefinitionListComponent extends BaseComponent implements OnInit, AfterContentChecked, AfterViewInit {
    // #region [ViewChild]
    @ViewChild('inputFilterRef') inputFilterRef: ElementRef<HTMLInputElement>;
    @ViewChildren('contextRef') contextRef: QueryList<ElementRef>;
    @ViewChildren('flowItemRef') flowItemRef: QueryList<FlowDefinitionItemComponent>;
    @ViewChildren('editContextButtonRef') editContextButtonRef: QueryList<ElementRef>;
    @ViewChildren('editContextInputRef') editContextInputRef: QueryList<ElementRef>;
    // #endregion

    // #region [properties]
    CONTEXT_MAX_LIMIT: number = 5 as const;

    flowDefinitions: FlowDefinition[] = null;
    filteredFlowDefinitions: FlowDefinition[] = [];
    flowDefinitionContexts: FlowDefinitionContext[] = [];
    contextModeDispatcher: FlowDefinition = null; /* flow-definition-item onde foi clicada a ação para entrar no modo Context */
    contextModeClickExclusionElements: any[] = [];
    reorderedFlowDefinitionIds: string[] = [];
    selectedFlowInbox: string;
    currentEditContextId: string = null;
    isBootstrapFinished: boolean = false;
    isFlowItemVisible: boolean = false;
    isContextMode: boolean = false;
    avoidedInitialContextClick: boolean = false;
    shouldShowEditContextInput: boolean = false;
    hasContextNameChanged: boolean = false;
    isActiveFilterOn: boolean;
    isActiveFilterOff: boolean;
    isPublishedFilterOn: boolean;
    isPublishedFilterOff: boolean;
    // #endregion

    // #region [getters]
    get shouldWaitForAcessoCidadaoRenewal(): boolean {
        let querystring = this.route.snapshot.queryParamMap.get('s');

        return !Utils.isNullOrEmpty(querystring)
            && atob(decodeURIComponent(querystring)).match(/\d{4}-\d{2}-\d{2}t\d{2}:\d{2}:\d{2}\.\d{3}z/gi) != null;
    }
    // #endregion

    constructor(
        route: ActivatedRoute,
        router: Router,
        spinner: NgxSpinnerService,
        toastr: ToastrService,
        private nameFilter: NameFilter,
        private isActiveFilter: IsActiveFilter,
        private isPublishedFilter: IsPublishedFilter,
        private changeDetectorRef: ChangeDetectorRef,
        private cookieService: CookieService,
        private confirmationService: ConfirmationService,
        private flowDefinitionService: FlowDefinitionService,
        private flowObjectDefinitionService: FlowObjectDefinitionService,
        private flowDefinitionContextService: FlowDefinitionContextService,
        private authService: AuthService
    ) {
        super(route, router, spinner, toastr);

        this.getCookies();
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit(skipLoading?: boolean, flowInboxToLoad?: string) {
        if (!skipLoading) {
            this.spinner.show();
        }

        if (flowInboxToLoad != null) {
            this.selectedFlowInbox = flowInboxToLoad;
        }

        try {
            const response = await this.flowDefinitionService.get({ flowInboxId: this.selectedFlowInbox });

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.initializeCollections(response.data);
        } finally {
            setTimeout(() => {
                this.isBootstrapFinished = true;
                this.spinner.hide();
                setTimeout(() => this.isFlowItemVisible = true, 400);
            }, 400);
        }
    }

    ngAfterContentChecked() {
        this.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit() {
        let interval = setInterval(() => {
            if (this.isBootstrapFinished) {
                this.adjustContextCards();
                clearInterval(interval);
            }
        }, 100);
    }

    // ======================
    // public methods
    // ======================

    toggleIsActive(isOnOnly: boolean) {
        if (isOnOnly == null) {
            this.isActiveFilterOn = false;
            this.isActiveFilterOff = false;
        } else if (isOnOnly) {
            this.isActiveFilterOn = true;
            this.isActiveFilterOff = false;
        } else {
            this.isActiveFilterOn = false;
            this.isActiveFilterOff = true;
        }

        this.setCookies();
        this.runFilter();
    }

    toggleIsPublished(isOnOnly: boolean) {
        if (isOnOnly == null) {
            this.isPublishedFilterOn = false;
            this.isPublishedFilterOff = false;
        } else if (isOnOnly) {
            this.isPublishedFilterOn = true;
            this.isPublishedFilterOff = false;
        } else {
            this.isPublishedFilterOn = false;
            this.isPublishedFilterOff = true;
        }

        this.setCookies();
        this.runFilter();
    }

    clearFilter() {
        this.inputFilterRef.nativeElement.value = '';
        this.runFilter();
    }

    runFilter() {
         this.filteredFlowDefinitions = this.isPublishedFilter.transform(
            this.isActiveFilter.transform(
                this.nameFilter.transform(
                    this.flowDefinitions,
                    this.inputFilterRef?.nativeElement.value
                ),
                this.isActiveFilterOn,
                this.isActiveFilterOff
            ),
            this.isPublishedFilterOn,
            this.isPublishedFilterOff
        );

        this.adjustContextCards();
    }

    hasFilteredFlowDefinition(context: FlowDefinitionContext) {
        for (let item of context.flowDefinitions) {
            if (this.filteredFlowDefinitions.find(x => x.id == item.id) != null) {
                return true;
            }
        }

        return false;
    }

    isFilteredFlowDefinition(flow: FlowDefinition) {
        return this.filteredFlowDefinitions.some(x => x.id == flow.id);
    }

    redirectToAction(event) {
        switch (event.actionType) {
            case Enums.FlowDefinitionItemActionType.Open:
                this.openFlowDefinition(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.Instantiate:
                this.instantiate(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.NewVersion:
                this.newVersion(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.Clone:
                this.clone(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.Publish:
                this.publish(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.Activate:
                this.activate(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.Deactivate:
                this.deactivate(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.Delete:
                this.delete(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.GroupByContext:
                this.groupFlowDefinitionByContext(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.AddItemToContext:
                this.addFlowDefinitionToContext(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.RemoveItemFromContext:
                this.removeFlowDefinitionFromContext(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.MoveUpInContext:
                this.moveUpInContext(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.MoveDownInContext:
                this.moveDownInContext(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.Pin:
                this.pin(event.model);
                break;

            case Enums.FlowDefinitionItemActionType.Unpin:
                this.unpin(event.model);
                break;
        }
    }

    newFlowDefinition() {
        this.router.navigate([Enums.PagesPaths.FlowDefinition]);
    }

    openFlowDefinition(flowDefinition: FlowDefinition) {
        this.router.navigate([Enums.PagesPaths.FlowDefinition, flowDefinition.id]);
    }

    instantiate(flowDefinition: FlowDefinition) {
        let environment = atob(this.cookieService.get('prodest-eflow-env'));
        if (environment.toLowerCase() == 'loc') {
            open(`${location.protocol}//${location.hostname}:44222${Enums.PagesPaths.FlowDefinition}/${flowDefinition.id}`);
        } else {
            open(`${location.origin.split('admin.').join('')}${Enums.PagesPaths.FlowDefinition}/${flowDefinition.id}`);
        }
    }

    newVersion(flowDefinition: FlowDefinition) {
        this.executeAction(
            this.newVersion.name,
            flowDefinition,
            Enums.Messages.NewVersionConfirmMessage,
            () => this.router.navigate([Enums.PagesPaths.FlowDefinition, flowDefinition.id])
        );
    }

    clone(flowDefinition: FlowDefinition) {
        this.executeAction(
            this.clone.name,
            flowDefinition,
            Enums.Messages.CloneConfirmMessage,
            async () => await this.ngOnInit(true)
        );
    }

    async publish(flowDefinition: FlowDefinition) {
        const response = await this.flowDefinitionService.getById(flowDefinition.id);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        flowDefinition = response.data;

        if (!(await FlowRules.isFlowDefinitionValid(flowDefinition, flowDefinition.isPublished, this.toastr, this.flowObjectDefinitionService))) return;

        this.executeAction(
            this.publish.name,
            flowDefinition,
            Enums.Messages.PublishConfirmMessage,
            async () => await this.ngOnInit(true)
        );
    }

    activate(flowDefinition: FlowDefinition) {
        this.executeAction(
            this.activate.name,
            flowDefinition,
            Enums.Messages.ActivateConfirmMessage,
            async () => await this.ngOnInit(true)
        );
    }

    deactivate(flowDefinition: FlowDefinition) {
        this.executeAction(
            this.deactivate.name,
            flowDefinition,
            Enums.Messages.DeactivateConfirmMessage,
            async () => await this.ngOnInit(true)
        );
    }

    delete(flowDefinition: FlowDefinition) {
        this.executeAction(
            this.delete.name,
            flowDefinition,
            Enums.Messages.DeleteConfirmMessage,
            async () => await this.ngOnInit(true)
        );
    }

    groupFlowDefinitionByContext(flowDefinition: FlowDefinition) {
        this.confirmationService.confirm({
            message: Enums.Messages.GroupByContextConfirmMessage.replace('{0}', flowDefinition.name),
            accept: () => {
                this.isContextMode = true;
                this.contextModeDispatcher = flowDefinition;
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    getContextFlowDefinitions(contextId: string): FlowDefinition[] {
        return contextId == null ? [] : this.flowDefinitionContexts.find(x => x.id == contextId).flowDefinitions;
    }

    moveUpInContext(flowDefinition: FlowDefinition) {
        this.moveInContext(flowDefinition, false);
    }

    moveDownInContext(flowDefinition: FlowDefinition) {
        this.moveInContext(flowDefinition, true);
    }

    pin(flowDefinition: FlowDefinition) {
        this.executeAction(
            this.pin.name,
            flowDefinition,
            Enums.Messages.PinConfirmMessage,
            async () => await this.ngOnInit(true)
        );
    }

    unpin(flowDefinition: FlowDefinition) {
        this.executeAction(
            this.unpin.name,
            flowDefinition,
            Enums.Messages.UnpinConfirmMessage,
            async () => await this.ngOnInit(true)
        );
    }

    async addFlowDefinitionToContext(flowDefinition: FlowDefinition) {
        this.spinner.show();

        try {
            const response = await this.flowDefinitionService.addToContext(flowDefinition.id, this.contextModeDispatcher);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            } else {
                this.toastr.success(response.message.description, Enums.Messages.Success);
            }

            await this.ngOnInit(true);
        } finally {
            this.exitContextMode();
            this.spinner.hide();
        }
    }

    async removeFlowDefinitionFromContext(flowDefinition: FlowDefinition) {
        this.spinner.show();

        try {
            const response = await this.flowDefinitionService.removeFromContext(flowDefinition.id, this.contextModeDispatcher);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.toastr.success(response.message.description, Enums.Messages.Success);
            await this.ngOnInit(true);
        } finally {
            this.exitContextMode();
            this.spinner.hide();
        }
    }

    async changeFlowInbox(event) {
        this.setCookies();
        await this.ngOnInit(false, this.selectedFlowInbox);
    }

    hasFilteredFlowItemInContext(contextId: string): boolean {
        let totalFlowDefinitionsInContext = this.flowDefinitionContexts.find(x => x.id == contextId).flowDefinitions.length;
        let filteredFlowDefinitionsInContext = this.filteredFlowDefinitions.filter(x => x.contextId == contextId).length;
        return totalFlowDefinitionsInContext != filteredFlowDefinitionsInContext;
    }

    shouldShowEditContext(context: FlowDefinitionContext): boolean {
        return this.isContextMode
            && context.id != null
            && context.flowDefinitions.find(x => x.id == this.contextModeDispatcher.id) != null;
    }

    shouldShowExitContextButton(context: FlowDefinitionContext): boolean {
        return this.isContextMode
            && context.flowDefinitions.map(x => x.id).includes(this.contextModeDispatcher?.id)
    }

    toggleEditContext(context: FlowDefinitionContext) {
        this.shouldShowEditContextInput = !this.shouldShowEditContextInput;
        this.currentEditContextId = context.id;
        setTimeout(() => this.editContextInputRef.toArray().forEach(x => x.nativeElement.select()), 1);
    }

    contextNameChange() {
        this.hasContextNameChanged = true;
    }

    getOrderedAdminUnitsList() {
        return this.authService.user?.unidadesPerfilAdministrador.sort((a, b) => {
            let first = `${a.organizacao.sigla} - ${a.nomeCurto}`;
            let second = `${b.organizacao.sigla} - ${b.nomeCurto}`;
            return first.localeCompare(second);
        });
    }

    async exitContextMode() {
        this.isContextMode = false;

        let contextId = this.contextModeDispatcher.contextId;

        // caso haja FlowDefinitions com ordenação alterada
        if (this.reorderedFlowDefinitionIds.length > 0) {
            this.spinner.show(contextId);
            let message = null;

            // filtra apenas os FlowDefinitions cuja ordenação tenha sido alterada
            let contextFlowDefinitions = this.getContextFlowDefinitions(contextId).filter(x => this.reorderedFlowDefinitionIds.includes(x.id));

            for (let item of contextFlowDefinitions) {
                try {
                    // atualiza os FlowDefinitions no banco
                    const response = await this.flowDefinitionService.updateRoot(item);

                    if (!response.isSuccess) {
                        this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                        message = null;
                        this.reload();
                        break;
                    }

                    message = response.message.description;
                } catch (error) {
                    this.toastr.error(Enums.Messages.GeneralError, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    console.error(error);
                    message = null;
                    this.reload();
                    break;
                }
            }

            // caso nenhum dos updateRoot() tenha retornado erro
            if (message != null) {
                this.toastr.success(message, Enums.Messages.Success);
                setTimeout(() => this.spinner.hide(contextId), 300);
            }
        }

        // caso o nome do Context tenha sido alterado
        if (this.hasContextNameChanged) {
            this.spinner.show(contextId);

            try {
                const response = await this.flowDefinitionContextService.update(this.flowDefinitionContexts.find(x => x.id == contextId));

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                } else {
                    this.toastr.success(response.message.description, Enums.Messages.Success);
                }
            } finally {
                this.spinner.hide(contextId);
            }
        }

        // reinicia variáveis de controle do Context Mode
        this.avoidedInitialContextClick = false;
        this.reorderedFlowDefinitionIds = [];
        this.contextModeDispatcher = null;
        this.shouldShowEditContextInput = false;
        this.currentEditContextId = null;
        this.hasContextNameChanged = false;
    }

    // ======================
    // private methods
    // ======================

    private initializeCollections(responseData: FlowDefinition[]) {
        this.flowDefinitions = responseData;
        this.flowDefinitionContexts = [];

        this.flowDefinitions.forEach(item => {
            if (item.contextId == null) {
                this.flowDefinitionContexts.push({ flowDefinitions: [item] });
            } else {
                let context = this.flowDefinitionContexts.find(x => x.id == item.contextId);
                if (context == null) {
                    this.flowDefinitionContexts.push({ id: item.contextId, flowDefinitions: [item] });
                } else {
                    context.flowDefinitions.push(item);
                }
            }
        });

        this.flowDefinitionContexts.forEach(async item => {
            item.flowDefinitions.sort((a, b) => a.contextIndex - b.contextIndex);

            if (item.id != null) {
                const response = await this.flowDefinitionContextService.getById(item.id);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                item.name = response.data.name;
            }
        });

        this.runFilter();
    }

    private executeAction(
        action: string,
        flowDefinition: FlowDefinition,
        confirmationMessage: string,
        callbackAfterSuccess: Function
    ) {
        this.confirmationService.confirm({
            message: confirmationMessage.replace('{0}', flowDefinition.name),
            accept: async () => {
                this.spinner.show();

                const response = await this.flowDefinitionService[action](flowDefinition.id);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.toastr.success(response.message.description, Enums.Messages.Success);
                callbackAfterSuccess();
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    // resolve o layout dos flow-definition-items quando pertencentes a um Context
    private adjustContextCards() {
        setTimeout(() => {
            let contexts = this.contextRef.toArray();
            if (contexts == null) return;

            contexts.forEach(item => {
                let context = item.nativeElement;
                let flowItems = this.flowItemRef.toArray().filter(x => [...context.children].includes(x.element.nativeElement));
                const flowItemOffset = parseInt(SCSS_VARS['$context-card-top-offset'].replace('px', ''));

                if (flowItems.length > 1) {
                    let totalHeight = 0;
                    flowItems.forEach(flowItem => totalHeight += flowItem.element.nativeElement.offsetHeight);

                    const totalOffset = (flowItems.length - 1) * flowItemOffset;

                    // calcula a altura total do container (div) do Context
                    context.style.height = `${totalHeight + totalOffset}px`;
                }

                flowItems.forEach((item, idx) => {
                    // posiciona os flow-definition-items relativamente ao container (div) do Context
                    // flow-definition-items já estão ordenados pelo "contextIndex"; seguir o "idx" evita problemas com flow-definition-items filtrados
                    let contextIndex = idx;
                    let flowItemElement = item.element.nativeElement.children[0] as HTMLElement;
                    flowItemElement.style.top = contextIndex == null || contextIndex == 0 ? null : `${contextIndex * flowItemOffset}px`;

                    // aplica efeito de profundidade/offset aos flow-definition-items inferiores
                    let sideOffset = 5; /* px */
                    flowItemElement.style.width = `calc(100% - ${sideOffset * 2 * contextIndex}px)`;
                    flowItemElement.style.marginLeft = `${sideOffset * contextIndex}px`;
                });
            });
        }, 1);
    }

    private moveInContext(flowDefinition: FlowDefinition, isDownwards: boolean = false) {
        // contorna artefatos visuais durante a animação de deslocamento dos flow-definition-items
        this.spinner.show(flowDefinition.contextId);
        let flowItems = this.flowItemRef.toArray().filter(x => x.model.contextId == flowDefinition.contextId);
        flowItems.forEach(x => x.element.nativeElement.children[0].style.opacity = '0');

        setTimeout(() => {
            // reordena os flow-definition-items no DOM
            let contextFlowDefinitions = this.getContextFlowDefinitions(flowDefinition.contextId);
            moveItemInArray(contextFlowDefinitions, flowDefinition.contextIndex, isDownwards ? flowDefinition.contextIndex + 1 : flowDefinition.contextIndex - 1);

            // reflete as mudanças de ordenação no ContextIndex de cada FlowDefinition afetado
            contextFlowDefinitions.forEach((item, idx) => {
                if (item.contextIndex != idx) {
                    this.reorderedFlowDefinitionIds.push(item.id);
                    item.contextIndex = idx;
                }
            });
            this.adjustContextCards();
        }, 300);

        // contorna artefatos visuais durante a animação de deslocamento dos flow-definition-items
        setTimeout(() => {
            flowItems.forEach(x => x.element.nativeElement.children[0].style.opacity = '1');
            this.spinner.hide(flowDefinition.contextId);
        }, 500);
    }

    private getCookies() {
        // lê os valores do cookie de filtros
        let filterValues = atob(this.cookieService.get('prodest-eflow-admin-filter')).split(';');

        // caso o cookie não exista
        if (filterValues.length == 1) {
            // valores padrão dos filtros de "Ativos" e "Publicados"
            this.isActiveFilterOn = true;
            this.isActiveFilterOff = false;
            this.isPublishedFilterOn = false;
            this.isPublishedFilterOff = false;
        } else {
            // atribui os valores parseados do cookie
            this.isActiveFilterOn = !!JSON.parse(String(filterValues[0].split(':')[1]).toLowerCase());
            this.isActiveFilterOff = !!JSON.parse(String(filterValues[0].split(':')[2]).toLowerCase());
            this.isPublishedFilterOn = !!JSON.parse(String(filterValues[1].split(':')[1]).toLowerCase());
            this.isPublishedFilterOff = !!JSON.parse(String(filterValues[1].split(':')[2]).toLowerCase());
        }

        // lê os valores do cookie da Caixa de Fluxos
        let inboxValue = atob(this.cookieService.get('prodest-eflow-admin-flow-inbox'));

        // caso o cookie não exista
        if (inboxValue == '') {
            if (this.authService.user.unidadesPerfilAdministrador.length > 0) {
                this.selectedFlowInbox = this.authService.user.unidadesPerfilAdministrador[0].guid;
            } else {
                this.toastr.error(Enums.Messages.NoFlowInboxError, Enums.Messages.Error, Utils.getToastrErrorOptions());
                console.error(Enums.Messages.NoFlowInboxError);
            }
        } else {
            this.selectedFlowInbox = inboxValue;
        }
    }

    private setCookies() {
        // monta a string de valores no formato esperado
        let filterValues = `active:${this.isActiveFilterOn}:${this.isActiveFilterOff}`;
        filterValues += `;published:${this.isPublishedFilterOn}:${this.isPublishedFilterOff}`;

        // escreve os valores no cookie de filtros
        this.cookieService.set('prodest-eflow-admin-filter', btoa(filterValues));

        // escreve os valores no cookie de Caixa de Fluxos
        this.cookieService.set('prodest-eflow-admin-flow-inbox', btoa(this.selectedFlowInbox));
    }

    private reload() {
        this.router.navigateByUrl('/0', { skipLocationChange: true }).then(() => this.router.navigate([Enums.PagesPaths.FlowDefinitionList]));
    }
}
