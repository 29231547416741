import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    ElementRef,
    ViewChild
} from '@angular/core';
import { ConfigSchemaFlowDefinition } from '../../../models/config-schema.model';
import { FlowDefinition } from '../../../models/flow.model';
import { Enums } from '../../../shared/enums';

@Component({
    selector: 'flow-definition-item',
    templateUrl: './flow-definition-item.component.html',
    styleUrls: ['./flow-definition-item.component.scss']
})
export class FlowDefinitionItemComponent implements OnInit, OnChanges {
    // #region [ViewChild]
    @ViewChild('contextModeRef') contextModeRef: ElementRef<HTMLElement>;
    // #endregion

    // #region [Type properties]
    FlowDefinitionItemActionType: typeof Enums.FlowDefinitionItemActionType = Enums.FlowDefinitionItemActionType;
    // #endregion

    // #region [properties]
    model: FlowDefinition;
    // #endregion

    // #region [getters]
    get hasHotConfigs(): boolean {
        if (!this.model.configSchema) return false;

        let configSchema = JSON.parse(this.model.configSchema) as ConfigSchemaFlowDefinition;
        return configSchema.hasAutomaticFlowDefinitionDeactivation || configSchema.hasAutomaticFlowInstanceCancellation;
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowDefinition;
    @Input() inputIsContextMode: boolean;
    @Input() inputIsLastContextIndex: boolean;
    @Input() inputHasReachedMaxLimit: boolean;
    @Input() inputHasFilteredFlowItemInContext: boolean;
    @Input() inputContextModeDispatcherId: string;
    @Input() inputDispatcherContextId: string;
    @Output() outputActionEvent = new EventEmitter<{ model: FlowDefinition, actionType: Enums.FlowDefinitionItemActionType }>();
    // #endregion

    constructor(public element: ElementRef) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        this.model = this.inputModel;
    }

    ngOnChanges(changes) {
        this.model = this.inputModel;
    }

    // ======================
    // public methods
    // ======================

    executeAction(actionType: Enums.FlowDefinitionItemActionType) {
        if ([Enums.FlowDefinitionItemActionType.MoveUpInContext, Enums.FlowDefinitionItemActionType.MoveDownInContext].includes(actionType)) {
            if (this.inputHasFilteredFlowItemInContext) return;
        }

        if ([Enums.FlowDefinitionItemActionType.AddItemToContext].includes(actionType)) {
            if (this.inputHasReachedMaxLimit) return;
        }

        this.outputActionEvent.emit({
            model: this.model,
            actionType: actionType
        });
    }

    // ======================
    // private methods
    // ======================
}
