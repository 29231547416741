<div *ngIf="model != null" class="card shadow" [class.pinned]="model.isPinned">
    <!-- Layer de edição de agrupamento por contexto -->
    <div class="context-mode" *ngIf="inputIsContextMode" [class.selected]="model.id == inputContextModeDispatcherId" #contextModeRef>
        <i class="fas fa-arrow-circle-up move"
           *ngIf="model.contextId != null && model.contextId == inputDispatcherContextId && model.contextIndex > 0"
           [matTooltip]="inputHasFilteredFlowItemInContext
                ? 'Não é possível reordenar o Agrupamento pois há um ou mais de seus Fluxos filtrados no momento'
                : 'Subir este Fluxo na ordenação do Agrupamento'
           "
           [class.disabled]="inputHasFilteredFlowItemInContext"
           (click)="executeAction(FlowDefinitionItemActionType.MoveUpInContext)"
        ></i>

        <i class="fas fa-arrow-circle-down move" featureHint="flowDefinitionContext|Move"
           *ngIf="model.contextId != null && model.contextId == inputDispatcherContextId && !inputIsLastContextIndex"
           [matTooltip]="inputHasFilteredFlowItemInContext
                ? 'Não é possível reordenar o Agrupamento pois há um ou mais de seus Fluxos filtrados no momento'
                : 'Descer este Fluxo na ordenação do Agrupamento'
           "
           [class.disabled]="inputHasFilteredFlowItemInContext"
           (click)="executeAction(FlowDefinitionItemActionType.MoveDownInContext)"
        ></i>

        <i class="fas fa-plus-circle add" featureHint="flowDefinitionContext|Add" featureHintExclusiveGroup
           *ngIf="model.id != inputContextModeDispatcherId && (model.contextId == null || model.contextId != inputDispatcherContextId)"
           [matTooltip]="inputHasReachedMaxLimit
                ? 'Não é possível adicionar ao Agrupamento em questão pois o mesmo atingiu o limite máximo de Fluxos'
                : 'Adicionar este Fluxo a um Agrupamento com o Fluxo previamente selecionado'
           "
           [class.disabled]="inputHasReachedMaxLimit"
           (click)="executeAction(FlowDefinitionItemActionType.AddItemToContext)"
        ></i>

        <i class="fas fa-minus-circle remove" featureHint="flowDefinitionContext|Remove"
           *ngIf="model.contextId != null && model.contextId == inputDispatcherContextId"
           matTooltip="Remover este Fluxo do Agrupamento atual"
           (click)="executeAction(FlowDefinitionItemActionType.RemoveItemFromContext)"
        ></i>
    </div>

    <div class="card-body">
        <h6 class="title m-0" (click)="executeAction(FlowDefinitionItemActionType.Open)">{{model.name}}</h6>

        <hr>

        <div class="body">
            <!-- Tags -->
            <div class="tags" featureHint="flowDefinitionItemTags" featureHintShowOn="right">
                <!-- Pin -->
                <span class="fas fa-thumbtack tag pin"
                      matTooltip="{{model.isPinned ? 'Desafixar' : 'Fixar'}} Fluxo no topo"
                      [class.on]="model.isPinned"
                      (click)="executeAction(model.isPinned ? FlowDefinitionItemActionType.Unpin : FlowDefinitionItemActionType.Pin)"
                ></span>

                <!-- Versão -->
                <span class="fa-stack tag version" matTooltip="Versão atual">
                    <i class="far fa-circle fa-stack-2x background"></i>
                    <span class="fa-stack-1x text">{{model.version}}</span>
                </span>

                <!-- Ativação -->
                <span class="fas fa-check-circle tag active"
                      matTooltip="Fluxo {{model.isActive ? 'ativo' : 'inativo'}}"
                      [class.on]="model.isActive"
                ></span>

                <!-- Publicação -->
                <span class="fas fa-cloud tag published"
                      matTooltip="Última versão do Fluxo {{model.isPublished ? '' : 'não '}}publicada"
                      [class.on]="model.isPublished"
                ></span>

                <!-- Configurações Imediatas -->
                <span class="fas fa-history tag hot-configs"
                      matTooltip="Fluxo {{hasHotConfigs ? '' : 'não '}}contém Configurações Imediatas ativadas"
                      [class.on]="hasHotConfigs"
                ></span>
            </div>

            <!-- Ações -->
            <div class="actions" featureHint="flowDefinitionItemActions" featureHintShowOn="right">
                <!-- Visualizar/Editar -->
                <a class="card-link"
                    matTooltip="{{model.isActive == false ? 'Visualizar' : (model.isPublished ? 'Visualizar' : 'Editar')}}"
                    (click)="executeAction(FlowDefinitionItemActionType.Open)"
                >
                    <i class="fas" [ngClass]="model.isActive == false ? 'fa-eye' : (model.isPublished ? 'fa-eye' : 'fa-pen')"></i>
                </a>

                <ng-container *ngIf="model.isActive">
                    <!-- Instanciar última versão -->
                    <a class="card-link"
                       *ngIf="model.isPublished || model.version > 1"
                       matTooltip="Instanciar a última versão publicada"
                       (click)="executeAction(FlowDefinitionItemActionType.Instantiate)"
                    >
                        <i class="fas fa-external-link-alt"></i>
                    </a>

                    <!-- Versionar -->
                    <a class="card-link"
                       *ngIf="model.isPublished"
                       matTooltip="Versionar"
                       (click)="executeAction(FlowDefinitionItemActionType.NewVersion)"
                    >
                        <i class="fas fa-code-fork"></i>
                    </a>

                    <!-- Clonar -->
                    <a class="card-link"
                       matTooltip="Clonar"
                       (click)="executeAction(FlowDefinitionItemActionType.Clone)"
                    >
                        <i class="fas fa-clone"></i>
                    </a>

                    <!-- Publicar -->
                    <a class="card-link"
                       *ngIf="!model.isPublished"
                       matTooltip="Publicar"
                       (click)="executeAction(FlowDefinitionItemActionType.Publish)"
                    >
                        <i class="fas fa-cloud-upload-alt"></i>
                    </a>

                    <!-- Agrupar Fluxos -->
                    <a class="card-link"
                       *ngIf="model.isActive"
                       matTooltip="Agrupar Fluxos"
                       (click)="executeAction(FlowDefinitionItemActionType.GroupByContext)"
                    >
                        <i class="fas fa-layer-group"></i>
                    </a>

                    <!-- Desativar -->
                    <a class="card-link"
                       *ngIf="model.isActive && (model.isPublished || model.version > 1)"
                       matTooltip="Desativar"
                       (click)="executeAction(FlowDefinitionItemActionType.Deactivate)"
                    >
                        <i class="fas fa-times-circle"></i>
                    </a>

                    <!-- Remover -->
                    <a class="card-link"
                       *ngIf="!model.isPublished && model.version == 1"
                       matTooltip="Remover"
                       (click)="executeAction(FlowDefinitionItemActionType.Delete)"
                    >
                        <i class="fas fa-trash"></i>
                    </a>
                </ng-container>

                <!-- Ativar -->
                <a class="card-link"
                   *ngIf="!model.isActive"
                   matTooltip="Ativar"
                   (click)="executeAction(FlowDefinitionItemActionType.Activate)"
                >
                    <i class="fas fa-check-circle"></i>
                </a>
            </div>
        </div>
    </div>
</div>
